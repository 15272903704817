<template>
  <div>
    <c-logo />
    <c-logo-page />
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="thanks_text"
      v-html="thanks_text"
    />
    <!--eslint-enable-->
    <div v-else>
      <c-title>Vielen Dank</c-title>

      <p class="mt-5">
        Ihr Antrag ist bei uns eingegangen. Sie erhalten in Kürze eine Bestätigung inklusive einer Kopie des Antrags per E-Mail. Wenn Sie diese nicht innerhalb weniger Minuten erhalten, überprüfen Sie bitte Ihren Spam-Ordner und wenden sich ggf. an die Hamburgische Kulturstiftung per
        <a
          href="mailto:info@claussen-simon-stiftung.de"
          target="_blank"
        >E-Mail</a>
        oder telefonisch unter 040 339099.
      </p>
    </div>
  </div>
</template>

<script>
import CLogo from '../components/CLogo.vue'
import CTitle from '../components/CTitle.vue'

export default {
  name: 'VThankYou',

  components: {
    CLogo,
    CTitle
  },

  computed: {
    thanks_text () {
      return this.$store.state.textsInfo.thanksText
    }
  }
}
</script>
